export const CONSOLE_URL = 'https://www.jidou-kun.com/console';
export const PRIVACY_POLICY_URL = '/privacy-policy';
export const CONTACT_US_URL = '/contact-us';
export const HERO_HASH = '/#hero';
export const FEATURES_HASH = '/#features';
export const PROFILE_HASH = '/#profile';
export const CONTACT_EMAIL = 'info@jidou-kun.com';

export const LANGUAGES = ['en', 'ja'];
export const UI_LANG_STORAGE_KEY = 'uiLang';
