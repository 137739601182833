import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import { PRIVACY_POLICY_URL, CONTACT_US_URL } from './lib/constants';
import i18n from './localization/i18n';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Header>
                <Home />
              </Header>
            }
          />
          <Route path={PRIVACY_POLICY_URL} element={<PrivacyPolicy />} />
          <Route
            path={CONTACT_US_URL}
            element={
              <Header>
                <ContactUs />
              </Header>
            }
          />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;
