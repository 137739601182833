import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ExcelImage from '../assets/excel-1500w.png';
import CalendarImage from '../assets/calendar-1500w.png';
import PointerImage from '../assets/pointer-1500w.png';
import LossImage from '../assets/loss-1500w.png';

const FeatureItem: FC<{
  image: string;
  alt: string;
  heading: string;
  text: string;
}> = ({ image, alt, heading, text }) => {
  return (
    <div
      className="flex-[0_0_auto] flex mt-3 items-center flex-col
                 w-full sm:w-1/4
                 px-6 sm:px-3"
    >
      <img className="h-[30px] object-cover mb-6" src={image} alt={alt} />
      <div className="text-title-text my-2 text-center text-[22px] font-semibold">
        {heading}
      </div>
      <div className="text-center mb-4 text-description-text text-[18px]">
        {text}
      </div>
    </div>
  );
};

const ElevatorPitchSection = () => {
  const { t } = useTranslation();

  return (
    <section
      className="w-full flex flex-wrap max-w-[1320px] items-start py-24
                 flex-col sm:flex-row
                 px-4 sm:px-12"
    >
      <FeatureItem
        image={ExcelImage}
        alt="excel"
        heading={t('Use Online Excel')}
        text={t(
          'It is possible not only to read from the provided URL but also to write to it!',
        )}
      />
      <FeatureItem
        image={CalendarImage}
        alt="calendar"
        heading={t('Set Your Schedule')}
        text={t(
          'Just set the scraping time, leave all the work to Mr. Automatic!',
        )}
      />
      <FeatureItem
        image={PointerImage}
        alt="one-click"
        heading={t('Concise and easy to use')}
        text={t(
          'Start scraping with just one click and easily check the results on both PCs and mobile devices!',
        )}
      />
      <FeatureItem
        image={LossImage}
        alt="no-loss"
        heading={t('Efficiency and Cost Reduction')}
        text={t('Let Mr. Automatic handle data scraping and go get some rest!')}
      />
    </section>
  );
};

export default ElevatorPitchSection;
