import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CONTACT_EMAIL } from '../lib/constants';

const paragraphStyle = 'mb-4 text-description-text';

const Item: FC<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  return (
    <>
      <h2 className="text-[1.2em] mt-8 mb-4 text-main-text">{title}</h2>
      <p className={paragraphStyle}>{description}</p>
    </>
  );
};

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-[800px] mx-auto p-5 text-title-text">
      <h1 className="text-[1.8em] mb-5 text-title-text">
        {t('Privacy Policy')}
      </h1>
      <p className={paragraphStyle}>{t('LAST_UPDATED')}</p>
      <Item
        title={t('Introduction')}
        description={t(
          "MJPoint Technologies Co., Ltd. is a company primarily engaged in web scraping. We fully recognize that our customers' personal information is vital and private. In handling this information during our operations, we strictly comply with relevant laws and internal regulations, taking appropriate protective measures to ensure we meet our customers' trust.",
        )}
      />
      <Item
        title={t('Compliance with Laws')}
        description={t(
          'We strictly adhere to laws related to personal information, government-established guidelines, and other norms, striving to appropriately protect personal information.',
        )}
      />
      <Item
        title={t('Compliance with Laws')}
        description={t(
          'In our business activities, we clearly define the purposes for using personal information and collect, use, and provide such information fairly and legally within the scope necessary to achieve those purposes. Furthermore, we take appropriate measures to ensure that the collected personal information is not used for purposes other than those intended.',
        )}
      />
      <Item
        title={t('Safe and Proper Management of Personal Information')}
        description={t(
          'We fully recognize the risks associated with handling personal information, such as unauthorized access, loss, destruction, alteration, and leakage, and we implement appropriate security measures to protect against these risks. In the event of an issue, we promptly and effectively take corrective actions.',
        )}
      />
      <Item
        title={t('Response to Inquiries')}
        description={t(
          'We will respond promptly and appropriately to requests from individuals regarding the personal information we handle, including requests to stop usage, correct, disclose, as well as any complaints or inquiries.',
        )}
      />
      <Item
        title={t('Continuous Improvement')}
        description={t(
          'We establish and maintain regulations and systems for the protection of personal information, ensuring that all employees strictly adhere to them. Additionally, we regularly review and strive for the continuous improvement of our personal information protection management system.',
        )}
      />
      <Item
        title={t('Inquiries Regarding the Handling of Personal Information')}
        description={t(
          'If you have any questions or concerns about our privacy policy, please feel free to contact us at the following address:',
        )}
      />
      <p className={paragraphStyle}>
        {`${t('E-mail')}: `}
        <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
      </p>
      <p className={paragraphStyle}>
        {t(
          'This privacy policy may be reviewed and updated periodically. Please check this page for the most up-to-date information.',
        )}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
