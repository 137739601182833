import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HeroSection from '../components/HeroSection';
import ElevatorPitchSection from '../components/ElevatorPitchSection';
import MainContentsSection from '../components/MainContentsSection';
import Footer from '../components/Footer';
import BackgroundImage from '../assets/curved6-1500h.jpg';
import { smoothScrollTo } from '../lib/utils';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      smoothScrollTo(hash.slice(1));
    }
  }, [location]);

  return (
    <div className="w-full h-auto flex min-h-screen items-center flex-col">
      <img
        src={BackgroundImage}
        alt="background-image"
        className="absolute top-0 right-0 bottom-0 left-auto w-1/2 h-[80vh] object-cover object-right rounded-bl-[10rem]
                   hidden sm:block"
      />
      <HeroSection />
      <ElevatorPitchSection />
      <MainContentsSection />
      <Footer />
    </div>
  );
};

export default Home;
