import { FC } from 'react';

const OutlineGrayButton: FC<{
  title: string;
}> = ({ title }) => {
  return (
    <button
      className="text-[#8392ab] border border-[#8392ab] hover:cursor-pointer hover:scale-105
                 transition-transform duration-150 px-6 py-3 rounded-full text-[13px] font-bold uppercase"
    >
      {title}
    </button>
  );
};

export default OutlineGrayButton;
