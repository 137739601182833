import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PinkGradientButton from './PinkGradientButton';
import OutlineGrayButton from './OutlineGrayButton';
import { FEATURES_HASH, HERO_HASH, CONTACT_US_URL } from '../lib/constants';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section
      id={HERO_HASH.slice(HERO_HASH.indexOf('#') + 1)}
      className="w-full flex max-w-[1320px] px-6 justify-center"
    >
      <div
        className="w-full min-h-[85vh] flex flex-col justify-center
                   mx-0 md:mx-10"
      >
        <div
          className="flex-col flex-none z-[1] mt-12 rounded-2xl bg-white bg-opacity-80
                     backdrop-saturate-[200%] backdrop-blur-[30px]
                     lg:w-[60%] md:w-full sm:w-[60%]
                     text-center sm:text-start
                     p-4 sm:p-12"
          style={{
            boxShadow:
              '0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06)',
          }}
        >
          <div className="bg-pink-gradient bg-clip-text text-transparent text-[34px] font-bold">
            {t('Data Scraping Tool: ')}
            <br className="block sm:hidden" />
            {t('Mr. Automatic!')}
          </div>
          <div className="text-title-text mb-6 text-[34px] font-bold">
            {t('What is Mr. Automatic?')}
          </div>
          <div className="text-main-text mb-4 text-[18px]">
            {t('Efficient web scraping service')}
            <br />
            {t('Automates routine tasks to improve productivity')}
          </div>
          <div
            className="flex mt-9 mb-4 gap-4
                       sm:flex-row flex-col
                       sm:items-start items-center"
          >
            <Link to={CONTACT_US_URL}>
              <PinkGradientButton title={t('Contact Us')} />
            </Link>
            <Link to={FEATURES_HASH}>
              <OutlineGrayButton title={t('View More')} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
