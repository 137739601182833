import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Logo from '../assets/mj_point_logo_white.svg';
import WavesImage from '../assets/waves-white.svg';
import {
  CONSOLE_URL,
  HERO_HASH,
  PROFILE_HASH,
  PRIVACY_POLICY_URL,
} from '../lib/constants';

const LinksContainer: FC<{
  classes?: string;
  title: string;
  children: ReactNode;
}> = ({ classes = '', title, children }) => {
  return (
    <div className={`flex-none flex flex-col items-start ${classes}`}>
      <span className="font-bold mb-2 text-base">{title}</span>
      {children}
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  const linkClasses =
    'text-gray-300 cursor-pointer transition-colors duration-300 mb-2 no-underline hover:text-white text-base';

  return (
    <footer
      className="w-full h-auto flex relative items-center flex-col
                 bg-gradient-to-br from-[#141727] to-[#3a416f]"
    >
      <div
        className="text-white w-full flex z-10 max-w-[1320px] justify-between
                   flex-col sm:flex-row
                   p-8 lg:p-12"
      >
        <div
          className="flex flex-col
                     items-center sm:items-start
                     mb-8 sm:mb-0"
        >
          <img
            className="w-[150px] h-auto object-cover"
            src={Logo}
            alt="logo"
          />
          <span>Copyright © 2024</span>
        </div>
        <div className="flex justify-between">
          <LinksContainer title={t('Company')}>
            <Link className={linkClasses} to={PROFILE_HASH}>
              {t('Company Profile')}
            </Link>
            <a
              className={linkClasses}
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Privacy Policy')}
            </a>
          </LinksContainer>
          <LinksContainer classes="ml:8 sm:ml-24" title={t('Pages')}>
            <a
              className={linkClasses}
              href={CONSOLE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Signin')}
            </a>
          </LinksContainer>
          <LinksContainer classes="ml:8 sm:ml-24" title={t('Services')}>
            <Link className={linkClasses} to={HERO_HASH}>
              {t('Mr. Automatic')}
            </Link>
          </LinksContainer>
        </div>
      </div>
      <img
        className="absolute top-0 right-0 left-auto w-full h-full opacity-70 object-cover"
        src={WavesImage}
        alt="waves"
      />
    </footer>
  );
};

export default Footer;
