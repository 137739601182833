import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = '404 - Not Found';
  }, []);

  return (
    <div className="w-full flex overflow-auto min-h-screen items-center flex-col justify-center">
      <h3>{t('OOPS! PAGE NOT FOUND')}</h3>
      <div className="flex relative items-center flex-col justify-center">
        <h1
          className="text-main-text mt-[-20px] font-extrabold mb-[-20px] tracking-[-20px]
                     text-[180px] sm:text-[250px]"
        >
          404
        </h1>
      </div>
      <div
        className="flex items-center flex-col justify-center
                   w-4/5 sm:w-[420px]"
      >
        <h2 className="text-center font-normal">
          {t('WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND')}
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
