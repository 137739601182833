import { FC } from 'react';

const PinkGradientButton: FC<{
  title: string;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
}> = ({ title, type, onClick }) => {
  return (
    <button
      className="text-white bg-pink-gradient shadow-md hover:cursor-pointer hover:scale-105 min-w-24
                 transition-transform duration-150 px-6 py-3 rounded-full border-none text-[13px] font-bold uppercase"
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default PinkGradientButton;
