import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PinkGradientButton from '../components/PinkGradientButton';

const ContactUs = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    alert(
      `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
    );
  };

  return (
    <div
      className="max-w-lg p-6 bg-white rounded-lg shadow-lg mt-24 mb-5
                 mx-5 sm:mx-auto"
    >
      <h2 className="text-2xl font-bold mb-4 text-title-text">
        {t('Contact Us')}
      </h2>
      <p className="mb-6 text-main-text">
        {t("We'll get back to you as soon as possible.")}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-main-text mb-1"
            htmlFor="name"
          >
            {t('Name')}
          </label>
          <input
            className="input input-bordered w-full"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={t('Enter your name')}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-main-text mb-1"
            htmlFor="email"
          >
            {t('E-mail')}
          </label>
          <input
            className="input input-bordered w-full"
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={t('Enter your email')}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-main-text mb-1"
            htmlFor="message"
          >
            {t('Message')}
          </label>
          <textarea
            className="textarea textarea-bordered w-full"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder={t('Enter your message')}
            rows={4}
            required
          />
        </div>
        <PinkGradientButton type="submit" title={t('Submit')} />
      </form>
    </div>
  );
};

export default ContactUs;
