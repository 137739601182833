import { ChangeEvent, FC, ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PinkGradientButton from './PinkGradientButton';
import Logo from '../assets/mj_point_logo_black.svg';
import {
  HERO_HASH,
  FEATURES_HASH,
  PROFILE_HASH,
  CONTACT_US_URL,
  CONSOLE_URL,
  UI_LANG_STORAGE_KEY,
  LANGUAGES,
} from '../lib/constants';

const LanguageSwitch: FC<{
  classes?: string;
}> = ({ classes = '' }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem(UI_LANG_STORAGE_KEY, i18n.language);
  }, [i18n.language]);

  return (
    <select
      className={`select select-bordered select-sm ${classes}`}
      value={i18n.language}
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
      }}
    >
      {LANGUAGES.map((language) => (
        <option key={language}>{language}</option>
      ))}
    </select>
  );
};

const MenuItems = () => {
  const { t } = useTranslation();
  const classes =
    'my-2 transition duration-300 no-underline hover:text-gray-500 text-[17px]';

  return (
    <div
      className="flex-row items-center flex-none mx-0.5
                 hidden sm:flex
                 gap-3 md:gap-8"
    >
      <Link className={classes} to={HERO_HASH}>
        {t('Top')}
      </Link>
      <Link className={classes} to={FEATURES_HASH}>
        {t('Features')}
      </Link>
      <Link className={classes} to={PROFILE_HASH}>
        {t('Company Profile')}
      </Link>
      <LanguageSwitch />
    </div>
  );
};

const HamburgerMenuItem: FC<{
  title: string;
  href: string;
  newPage?: boolean;
}> = ({ title, href, newPage = false }) => {
  return (
    <li className="cursor-pointer rounded-sm list-item">
      <div
        className="w-full inline-flex items-center transition-colors duration-300 py-2 px-4
                   rounded-md text-gray-500 bg-transparent hover:text-white hover:bg-[#595959]"
      >
        {newPage ? (
          <a
            className="w-full cursor-pointer flex text-[15px] font-medium"
            href={href}
            target={newPage ? '_blank' : undefined}
            rel={newPage ? 'noreferrer noopener' : undefined}
          >
            {title}
          </a>
        ) : (
          <Link to={href}>{title}</Link>
        )}
      </div>
    </li>
  );
};

const HamburgerMenu = () => {
  const { t } = useTranslation();

  return (
    <div
      className="cursor-pointer relative rounded-[2px] group
                 inline-block sm:hidden"
    >
      <div className="text-gray-500 fill-gray-500 w-full inline-flex items-center py-2 rounded-sm">
        <svg viewBox="0 0 1024 1024" className="w-6 h-6 ml-4">
          <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
        </svg>
      </div>
      <ul
        className="group-hover:flex left-[-45px] w-max absolute min-w-full transition-all duration-300
                   flex-col items-stretch border border-[#d9d9d9] rounded-[4px] list-none bg-white list-inside z-[100]
                   hidden sm:hidden"
      >
        <HamburgerMenuItem title={t('Signin')} href={CONSOLE_URL} newPage />
        <HamburgerMenuItem title={t('Features')} href={FEATURES_HASH} />
        <HamburgerMenuItem title={t('Contact Us')} href={CONTACT_US_URL} />
      </ul>
    </div>
  );
};

const Header: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="top-0 left-auto right-auto bottom-auto w-full flex z-30 fixed px-6 justify-center">
        <nav
          className="flex flex-wrap items-center justify-between w-full h-[60px] mt-4 px-6 py-2 rounded-[40px] bg-white
                     bg-opacity-80 shadow-md backdrop-saturate-[200%] backdrop-blur-[30px] text-main-text max-w-[1320px]"
        >
          <div className="flex items-center justify-between w-full text-black">
            <img
              className="w-[150px] h-auto object-cover"
              src={Logo}
              alt="logo"
            />
            <MenuItems />
            <div
              className="items-center justify-between flex-row
                         hidden sm:flex"
            >
              <a href={CONSOLE_URL} target="_blank" rel="noreferrer noopener">
                <PinkGradientButton title={t('Signin')} />
              </a>
            </div>
            <LanguageSwitch
              classes="ml-2
                       block sm:hidden"
            />
            <HamburgerMenu />
          </div>
        </nav>
      </div>
      {children}
    </>
  );
};

export default Header;
